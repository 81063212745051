<template>
    <div>
        <div class="header">
            <van-icon name="arrow-left" @click="$router.push({path: '/self/service/bill/billList'})"/>
            <div class="title">零钱明细</div>
        </div>
        <div class="top">
            <div class="summary">
                <img :src="bill.avatar" alt="">
                <div>{{ bill.text }}</div>
                <div v-if="bill.zrtype == 2" class="value">+{{ returnFloat(bill.value) }}</div>
                <div v-if="bill.zrtype == 1" class="value">-{{ returnFloat(bill.value) }}</div>
                <div v-if="bill.zrtype == 3" class="value">{{ returnFloat(bill.value) }}</div>
            </div>
            <div class="detail">
                <div class="item">
                    <div class="label">当前状态</div>
                    <div v-if="bill.zrtype == 1">对方已收钱</div>
                    <div v-if="bill.zrtype == 2">已存入零钱</div>
                    <div v-if="bill.zrtype == 3">已存入零钱</div>
                </div>
                <div class="item">
                    <div class="label">转账说明</div>
                    <div>微信转账</div>
                </div>
                <div class="item">
                    <div class="label">转账时间</div>
                    <div>{{ bill.transferTime }}</div>
                </div>
                <div class="item">
                    <div class="label">收款时间</div>
                    <div>{{ bill.incomeTime }}</div>
                </div>
                <div class="item">
                    <div class="label">支付方式</div>
                    <div>{{ bill.type }}</div>
                </div>
                <div class="item">
                    <div class="label">转账单号</div>
                    <div>{{ bill.number }}</div>
                </div>
                <div class="item" v-if="bill.type=='零钱'">
                    <div class="label">零钱余额</div>
                    <div>{{ bill.balance }}</div>
                </div>
            </div>
        </div>
        <div class="bottom">
            <div class="item">
                <div>定位到聊天位置</div>
                <van-icon name="arrow" color="#d8d8d8" />
            </div>
            <div class="item">
                <div>申请转账电子凭证</div>
                <van-icon name="arrow" color="#d8d8d8"/>
            </div>
            <div class="item">
                <div>查看往来转账</div>
                <van-icon name="arrow" color="#d8d8d8"/>
            </div>

        </div>


    </div>


</template>

<script>
import { mapState } from 'vuex'
    export default {
        data() {
            return {
                bill: ''
            }
        },
        computed: {
            ...mapState({
                billList: state => state.billList
            })
        },
        mounted() {
            this.bill = this.billList.filter(bill => bill.id == this.$route.query.id)[0]
            console.log(this.bill)
        },
        methods:{
                   returnFloat(num) {
             console.log(num)
    num = num.toString().replace(/,/g, ""); // 转成字符串类型  如能确定类型 这步可省去
     
      if (num.indexOf(".") !== -1) {
        let [integerPart, decimalPart] = num.split(".");
     
        if (decimalPart.length > 2) {
          decimalPart = decimalPart.substring(0, 2);
        } else if (decimalPart.length === 1) {
          decimalPart += "0";
        }
     
        num = `${integerPart}.${decimalPart}`;
      } else {
        num += ".00";
      }
     
      return num;
    }
        }

    }

</script>

<style lang="less" scoped>
.header {
    position: relative;
    z-index: 99;
    // overflow: hidden;
    height: 45px;
    display: flex;
    align-items: center;
    // font-size: 15px;
    padding: 0 15px 0 10px;
    line-height: 45px;
    background: #fff;
    opacity: 1;
    color: #000;
    user-select: none;
    -webkit-user-select: none;
    transition: all 0.3s linear;
    text-align: center;
    font-size: 17px;
    font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Noto Sans CJK SC, WenQuanYi Micro Hei, Arial, sans-serif;
    .title {
        flex: 1;
        margin-left: 17px;
    }
    .bill {
        font-weight: 200;
        font-size: 14px;
    }
}
.top {
    background: #fff;
    .summary {
        padding-top: 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid #f2f1f1;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 30px;
        margin-right: 30px;
        img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-bottom: 10px;
        }
        .value {
            font-size: 30px;
            font-weight: bold;
        }
    }
    .detail {
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 30px;
        padding-right: 30px;
        .item {
            display: flex;
            margin-bottom: 10px;
            .label {
                color: #A9A9A9;
                margin-right: 10px;
            }
        }
    }
}
.bottom {
    margin-top: 10px;
    background: #fff;
    padding-left: 30px;
    padding-right: 30px;
    .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        border-bottom: 1px solid #f2f1f1;
    }
}
</style>